import * as Styled from './OxArcheFeat.styled';

import React, { useContext, useEffect, useState } from 'react';
import { LayoutContext } from 'src/context/LayoutContext';
import { OxWaveText } from 'src/components/OxWaveText';

export enum EOxArcheFeatVariant {
    Default = 'Default',
    Reverse = 'Reverse'
}

type TProps = {
    titleAsHero?: string[][];
    description: string;
    variant: EOxArcheFeatVariant;
};

export const OxArcheFeat = ({
    titleAsHero,
    description,
    variant,
    ...props
}: SCProps<'div', TProps>): JSX.Element => {
    const [componentIsReady, setComponentIsReady] = useState(false);
    const layoutContext = useContext(LayoutContext);

    useEffect(() => {
        setTimeout(() => {
            setComponentIsReady(true);
        }, 1000);
    }, []);

    return (
        <Styled.Container variant={variant} {...props}>
            <Styled.TitleAsHero variant={variant}>
                <Styled.TitleAsHeroIcon name="ouronyx-icon" height={34} />
                <OxWaveText
                    text={titleAsHero}
                    animationInit={!layoutContext.websiteLoaderActive && componentIsReady}
                />
            </Styled.TitleAsHero>
            <Styled.ContentWrapper variant={variant}>
                <Styled.Description>{description}</Styled.Description>
            </Styled.ContentWrapper>
        </Styled.Container>
    );
};
