import { EFontWeight, EFonts } from 'src/config/enums';
import { fluidSizing } from 'src/utils';
import styled from 'styled-components';

import { createTextStyle } from 'src/utils/createTextStyle';

export const Quote = styled.p`
    ${createTextStyle(EFonts.Hatton, EFontWeight.Light, [18, 22, 25], [28, 28, 33])}
    ${fluidSizing([
        { prop: 'padding-top', values: [20, 40, 55] },
        { prop: 'padding-bottom', values: [20, 40, 55] }
    ])}
  text-align: center;
`;
